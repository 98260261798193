import { setCoverType } from 'components/Plans/Logic/Plans.reducer';
import { useAppDispatch, useAppSelector } from 'hooks/ReduxHook';
import { Translate } from 'hooks/Translation';
import { setShowModal } from 'store/modal';

const PolicyTypeModalLogic = () => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.premium);
  const { isOldCar, selectSumInsured } = useAppSelector((state) => state.base);
  const { coverType } = useAppSelector((state) => state.plans);
  const { type } = useAppSelector((state) => state.vhlType);
  const { t } = Translate();

  const data = [
    {
      title: t('comprehensive'),
      isSelected: false,
      info: t('comprehensive_info'),
      value: '01'
    },
    {
      title: t('third_party'),
      isSelected: false,
      info: t('third_party_info'),
      value: '02'
    },
    {
      title: t('third_party_ft'),
      isSelected: false,
      info: t('third_party_ft_info'),
      value: '03'
    }
  ];

  const coverTypeData = () => {
    if (type === 'car') {
      const carData = data.filter((item) => item.value !== '02');
      return isOldCar ? carData.reverse() : carData;
    }
    if (type === 'motorcycle') {
      return data.filter((item) => item.value !== '03');
    }
  };

  const changeEvent = (value) => {
    dispatch(setCoverType(value));
    dispatch(setShowModal(false));
  };

  return {
    changeEvent,
    data: coverTypeData(),
    isLoading,
    coverType,
    selectSumInsured
  };
};

export default PolicyTypeModalLogic;
