import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { current } from '@reduxjs/toolkit';

import { selectedData } from 'components/Plans/Logic/Plans.reducer';
import useCleverTapPush from 'hooks/CleverTapData';
import DataLayer from 'hooks/DataLayerPush';
import { useAppDispatch, useAppSelector } from 'hooks/ReduxHook';
import { useStepper } from 'hooks/Stepper';
import { Translate } from 'hooks/Translation';
import { setFieldValue } from 'pages/Home/SubComponents/VehicleForm/Logic/VehicleForm.reducer';
import { useGetPremiumMutation } from 'services/api/services';
import { premiumRequest } from 'services/decorator/request';
import { setReferralCode } from 'store/base';
import { setModalProps } from 'store/modal';

const CustomerDetailsLogic = () => {
  const useFormMethod = useForm({ mode: 'all' });
  const [formError, setFormError] = useState(null);
  const { t } = Translate();
  const { confirmVariation, vix } = useAppSelector((state) => state.vix);
  const { modal } = useAppSelector((state) => state.modal);
  const vehicleForm = useAppSelector((state) => state.vehicleForm);
  const { coverType, vhlType } = useAppSelector((state) => ({
    vhlType: state.vhlType,
    coverType: state.plans.coverType
  }));
  const dispatch = useAppDispatch();
  const { currentRoute, moveNext } = useStepper();
  const { pushData } = useCleverTapPush(currentRoute);
  const { sendPageData } = DataLayer(currentRoute);
  const [getPremium, { isLoading }] = useGetPremiumMutation();
  const premium = useAppSelector(premiumRequest);
  const { referralCode } = useAppSelector((state) => state.base);

  useEffect(() => {
    pushData();
    sendPageData();
  }, []);

  const onSubmit = async (formData) => {
    dispatch(
      setModalProps({
        type: 'api'
      })
    );
    const { referralCode, ...rest } = formData;
    if (referralCode) {
      dispatch(setReferralCode(referralCode));
    }
    dispatch(setFieldValue({ ...vehicleForm, ...rest }));
    try {
      const {
        data: { item }
      } = await getPremium({
        ...premium,
        address1: rest.address,
        name: rest.fullName,
        email: rest.email
      }).unwrap();
      moveNext();
      dispatch(selectedData(item[0]));
    } catch (error) {
      console.log(error);
    }
  };

  const onError = (error) => setFormError(error);

  return {
    onSubmit,
    onError,
    formError,
    useFormMethod,
    t,
    vix,
    confirmVariation,
    vehicleForm,
    referralCode,
    isLoading,
    modalProps: modal,
    showSumInsured: coverType === '02' && vhlType.type === 'motorcycle'
  };
};

export default CustomerDetailsLogic;
