import {
  addOnsPageview,
  customerConfirmationPageview,
  gtmOrderPageData,
  gtmVixData,
  paymentSuccessPageview,
  profilePageview,
  verificationPageview
} from 'services/decorator/pushData/selector';

export const routeToEventMap = {
  '/user-verification': {
    eventName: 'Verification_pageview',
    getData: verificationPageview,
    getProfileData: profilePageview,
    gtm: gtmVixData,
    gtmEvent: {
      event: 'submit_vehicle_details',
      event_category: 'Home',
      event_action: 'Submit Vehicle Details',
      event_label: 'Vehicle Details'
    }
  },
  '/plans': {
    gtm: gtmOrderPageData,
    gtmEvent: {
      event: 'select_item',
      event_category: 'Plans',
      event_action: 'Select Item'
    }
  },
  '/addons': {
    eventName: 'Addons_pageview',
    getData: addOnsPageview,
    getProfileData: profilePageview,
    gtm: gtmOrderPageData,
    gtmEvent: {
      event: 'view_item',
      event_category: 'Premium Details',
      event_action: 'View Item'
    }
  },
  '/customer-details': {
    eventName: 'CustomerDetails_pageview',
    getData: addOnsPageview,
    getProfileData: profilePageview,
    gtm: gtmOrderPageData,
    gtmEvent: {
      event: 'add_to_cart',
      event_category: 'Premium Details',
      event_action: 'Add To Cart',
      event_label: 'Coverage Value Preference'
    }
  },
  '/checkout': {
    eventName: 'CustomerConfirmation_pageview',
    getData: customerConfirmationPageview,
    getProfileData: profilePageview,
    gtm: gtmOrderPageData,
    gtmEvent: {
      event: 'begin_checkout',
      event_category: 'User Details',
      event_action: 'Begin Checkout'
    }
  },
  '/payment-status': {
    eventName: 'PaymentSuccess_pageview',
    getData: paymentSuccessPageview,
    getProfileData: profilePageview,
    gtm: gtmOrderPageData,
    gtmEvent: {
      event_category: 'Payment Status'
    }
  }
};
