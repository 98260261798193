import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';

export const useRelicData = () => {
  const licenseK = process.env.REACT_APP_RELIC_LICENSEKEY;
  const relicAccID = process.env.REACT_APP_RELIC_ACCID;
  const relicAppID = process.env.REACT_APP_RELIC_APPID;
  const relicTrustKey = process.env.REACT_APP_RELIC_TRUSTKEY;
  const optionsProd = {
    init: {
      distributed_tracing: {
        enabled: true,
        cors_use_newrelic_header: true,
        cors_use_tracecontext_headers: true,
        allowed_origins:
          process.env.REACT_APP_ENV === 'PRODUCTION'
            ? ['https://spp-svc.uat-pos.com']
            : [process.env.REACT_APP_BASEURL]
      },
      privacy: { cookies_enabled: true },
      ajax: { deny_list: ['bam.nr-data.net'] }
    },
    info: {
      beacon: 'bam.nr-data.net',
      errorBeacon: 'bam.nr-data.net',
      licenseKey: licenseK,
      applicationID: relicAppID,
      sa: 1
    },
    loader_config: {
      accountID: relicAccID,
      trustKey: relicTrustKey,
      agentID: relicAppID,
      licenseKey: licenseK,
      applicationID: relicAppID
    }
  };

  // eslint-disable-next-line no-new
  new BrowserAgent(optionsProd);
};

export default useRelicData;
