import { createSelector } from '@reduxjs/toolkit';

import {
  addOnListTitle,
  logoArray,
  maleFemaleLabels,
  planTypes,
  policyTypeLabel
} from 'constants/label';
import {
  capitalizeFirstLetter,
  convertDOBDate,
  convertToEpoch,
  sliceFirstTwoWords,
  sliceFirstWord
} from 'utils/helper';

const vix = (state) => state.vix;
const vehicleForm = (state) => state.vehicleForm;
const plans = (state) => state.plans;
const addOns = (state) => state.addOns;
const checkout = (state) => state.checkout;
const vhlType = (state) => state.vhlType;

export const verificationPageview = createSelector(
  [vehicleForm, vix, vhlType],
  (vehicleForm, vix, vhlType) => {
    const { confirmVariation, vix: vixData } = vix;
    return {
      'Policy expiry date': convertToEpoch(vixData?.expiryDate),
      'Vehicle registration number': vehicleForm?.regNo,
      'Vehicle type': vhlType?.type,
      'Vehicle Details': confirmVariation?.desc,
      NCD: vixData?.ncdAmt
    };
  }
);

export const quotationPlansPageview = createSelector(
  [vhlType, plans],
  (vhlType, plans) => {
    const { coverType, lowestQuote } = plans;
    const { type } = vhlType;

    return {
      'Policy type': planTypes?.[policyTypeLabel?.[coverType]],
      'Latest lowest quote': lowestQuote?.price,
      'Lowest quote insurance provider': lowestQuote?.compName,
      'Vehicle type': type
    };
  }
);

export const addOnsPageview = createSelector([vhlType], (vhlType) => {
  return {
    'Vehicle type': `insurance - ${vhlType?.type}`
  };
});

export const customerConfirmationPageview = createSelector(
  [vehicleForm, vix, plans, addOns, checkout, vhlType],
  (vehicleForm, vix, plans, addOns, checkout, vhlType) => {
    const { confirmVariation, vix: vixData } = vix;
    const { totalPrice } = checkout;
    const { selectedData, selectedPlan } = plans;

    return {
      'Policy expiry date': convertToEpoch(selectedData?.expiryDate),
      'Vehicle registration number': vehicleForm?.regNo,
      'Vehicle type': vhlType?.type,
      'Vehicle Details': confirmVariation?.desc,
      'Add-ons': addOns?.addOnData?.[selectedPlan]
        ?.map((addOn) => addOnListTitle[addOn])
        .join(', '),
      'Insurance provider': logoArray?.[selectedPlan]?.name,
      'Policy premium': selectedData?.tariffPremium,
      'Sum insured': selectedData?.sumInsured,
      NCD: selectedData?.ncdAmt,
      Amount: +totalPrice?.toFixed(2)
    };
  }
);

export const profilePageview = createSelector(
  [vehicleForm, plans, vix],
  (vehicleForm, plans, vix) => {
    const { lowestQuote } = plans;
    return {
      Site: {
        Identity: vehicleForm?.newIc || vehicleForm.passportNo,
        Email: vehicleForm?.email,
        Phone: `+60${vehicleForm?.mobileNumber}`,
        Name: vehicleForm?.fullName,
        Gender: vehicleForm?.gender,
        DOB: convertDOBDate(vehicleForm?.birthDate),
        Age: vehicleForm?.age,
        'Latest lowest quote': lowestQuote?.price,
        'Latest quote date': new Date(),
        'Marital status': vehicleForm?.maritalStatus,
        'MSG-email': true,
        'MSG-sms': true,
        'MSG-whatsapp': true
      }
    };
  }
);

export const paymentSuccessPageview = createSelector(
  [vehicleForm, checkout, vhlType, plans, vix, addOns],
  (vehicleForm, checkout, vhlType, plans, vix, addOns) => {
    const { coverType, selectedData, selectedPlan } = plans;
    const { confirmVariation } = vix;
    const { type } = vhlType;
    return {
      'Vehicle type': type,
      Amount: checkout?.insurance?.grossDue2,
      'ID type': vehicleForm?.newIc ? 'NRIC' : 'Passport',
      'ID number': vehicleForm?.newIc || vehicleForm?.passportNo,
      'Policy type': planTypes?.[policyTypeLabel?.[coverType]],
      'Insurance provider': logoArray?.[selectedPlan]?.name,
      'Policy expiry date': convertToEpoch(selectedData?.expiryDate),
      'Policy premium': selectedData?.tariffPremium,
      'Vehicle registration number': vehicleForm?.regNo,
      'Vehicle Details': confirmVariation?.desc,
      'Sum insured vehicle': selectedData?.sumInsured,
      NCD: selectedData?.ncdAmt,
      'Add-ons':
        addOns?.addOnData?.[selectedPlan]
          ?.map((addOn) => addOnListTitle[addOn])
          .join(', ') || 'N/A'
    };
  }
);

export const gtmVixData = createSelector(
  [vix, vhlType, vehicleForm],
  (vix, vhlType, vehicleForm) => {
    const { confirmVariation, vix: vixData } = vix;
    return {
      vehicle_variant: confirmVariation?.desc,
      vehicle_type: vhlType?.type,
      vehicle_make: vixData?.arrResExtraParam?.item?.[3]?.value,
      customer_age: vehicleForm.age,
      customer_gender: maleFemaleLabels[vehicleForm.gender]
    };
  }
);

export const gtmOrderPageData = createSelector(
  [plans, vhlType, vehicleForm, vix, checkout],
  (plans, vhlType, vehicleForm, vix, checkout) => {
    const { coverType, selectedData, selectedPlan } = plans;
    const { confirmVariation, vix: vixData } = vix;
    return {
      event_label: `${sliceFirstTwoWords(logoArray?.[selectedPlan]?.name)} ${
        planTypes?.[policyTypeLabel?.[coverType]]
      }`,
      currency: 'RM',
      value: selectedData?.grossDue,
      vehicle_type: vhlType.type,
      customer_age: vehicleForm.age,
      customer_gender: vehicleForm.gender,
      vehicle_make: vixData?.arrResExtraParam?.item?.[3]?.value,
      vehicle_variant: confirmVariation?.desc,
      items: [
        {
          item_id: selectedPlan,
          item_name: `${sliceFirstTwoWords(logoArray?.[selectedPlan]?.name)} ${
            planTypes?.[policyTypeLabel?.[coverType]]
          }`,
          item_brand: sliceFirstWord(logoArray?.[selectedPlan]?.name),
          item_category: 'Vehicle Insurance',
          item_category_2: capitalizeFirstLetter(vhlType.type),
          item_category_3: planTypes?.[policyTypeLabel?.[coverType]],
          item_variant: 'Market Value - 12 months',
          currency: 'RM',
          price: checkout?.totalPrice?.toFixed(2),
          quantity: '1'
        }
      ]
    };
  }
);
