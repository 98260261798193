import { setSort } from 'components/Plans/Logic/Plans.reducer';
import DataLayer from 'hooks/DataLayerPush';
import { useAppDispatch, useAppSelector } from 'hooks/ReduxHook';
import { useStepper } from 'hooks/Stepper';
import { Translate } from 'hooks/Translation';

const PriceSortModalLogic = () => {
  const { currentRoute } = useStepper();
  const { sendEventData } = DataLayer(currentRoute);
  const dispatch = useAppDispatch();
  const plans = useAppSelector((state) => state.plans);
  const { t } = Translate();
  const chooseSort = (e, sort) => {
    sendEventData(e);
    dispatch(setSort(sort));
  };

  const highlight = (id) => {
    return plans.sort === id ? 'item-highlight' : 'not-highlight';
  };

  return {
    t,
    chooseSort,
    highlight,
    plans
  };
};

export default PriceSortModalLogic;
