import React from 'react';

import './PlanBox.scss';
import PlanBoxLogic from './Logic/PlanBox.logic';
import { ArrowDown, ArrowUp, NotMatch } from 'assets/images';
import { LogoInsurer } from 'components';
import { policyTypeLabel } from 'constants/label';
import { formatNumber } from 'utils/helper';

function PlanBox({ benefits, id, plan, plans, selectedPlan }) {
  const { benefitsData, isExpanded, isReadMore, noArrow, t, toggleShowHide } =
    PlanBoxLogic(id, plans, benefits);
  return (
    <div
      className={`plan-box ${
        benefitsData?.length === 0 ? `${t('benefit_empty')}` : ''
      }`}
    >
      <div className="company">
        <div className="logo">
          <LogoInsurer companyCode={plan?.compCode} />
          <span className="label">{t(policyTypeLabel[plan?.coverCode])}</span>
        </div>
        <div className="plan-amount">
          <button
            className="blue-button medium hover"
            onClick={(e) => {
              selectedPlan(e, plan);
            }}
          >
            RM {formatNumber(plan.grossPrem)}
          </button>
          <div className="desc">
            {t('your_saving')}
            <span className="highlight bold">
              RM{formatNumber(plan?.ncdAmt)}
            </span>
          </div>
        </div>
      </div>
      <div
        className={`description ${benefitsData?.length === 0 ? 'mt-3' : ''}`}
      >
        <div className={'description-list '}>
          <ul>
            {benefitsData?.length === 0 ? (
              <li className="error">
                <img className="not-match" src={NotMatch} alt="not-match" />
                <span className="label">{t('error_no_info')}</span>
              </li>
            ) : (
              <span>
                {benefitsData?.map((benefit, indexBenefit) => {
                  return <li key={indexBenefit}>{t(benefit)}</li>;
                })}
              </span>
            )}
          </ul>
        </div>
        {isReadMore && !noArrow ? (
          <div className="description-more">
            <span className="read-more" onClick={() => toggleShowHide(id)}>
              {isExpanded ? (
                <img src={ArrowUp} alt="arrow-up" />
              ) : (
                <img src={ArrowDown} alt="arrow-down" />
              )}
            </span>
          </div>
        ) : null}
      </div>
      <div className="amount">
        <button
          className="blue-button medium hover"
          data-event={plan.grossPrem}
          onClick={(e) => {
            selectedPlan(e, plan);
          }}
        >
          RM {formatNumber(plan.grossPrem)}
        </button>
        <div className="desc">
          {t('your_saving')}{' '}
          <span className="highlight bold">RM {formatNumber(plan.ncdAmt)}</span>
        </div>
      </div>
    </div>
  );
}

export default PlanBox;
