import { useState } from 'react';

import { setTotalPrice } from 'components/Checkout/Logic/Checkout.reducer';
import { benefitCodeToTitle, policyTypeLabel } from 'constants/label';
import useMobileSize from 'hooks/IsMobile';
import { useAppDispatch, useAppSelector } from 'hooks/ReduxHook';
import { useStepper } from 'hooks/Stepper';
import { Translate } from 'hooks/Translation';
import { formatDateRange, formatNumber } from 'utils/helper';

const PaymentBoxLogic = () => {
  const dispatch = useAppDispatch();
  const { isIpadView } = useMobileSize();
  const { t } = Translate();
  const { moveNext } = useStepper();
  const { coverType, planData, selectedPlan } = useAppSelector(
    (state) => state.plans
  );
  const { newExpiryDate, selectSumInsured, steps } = useAppSelector(
    (state) => state.base
  );
  const { addOnData } = useAppSelector((state) => state.addOns);
  const { [coverType]: plan } = useAppSelector((state) => state.premium);

  const getPlan = plan?.[selectSumInsured]?.filter(
    (item) => item.compCode === selectedPlan
  )[0];
  const getAddOns = addOnData?.[selectedPlan];
  const [closeModal, setCloseModal] = useState(false);

  const getCoveragePeriod = formatDateRange(newExpiryDate?.expiryDate);

  const getPrice = (addOnCode) =>
    planData?.[coverType]?.[selectSumInsured]?.[selectedPlan]
      ?.filter((addOn) => addOn.benCode === addOnCode)
      .map((addOn) => addOn.benPremium)[0];

  const addOnsTotal =
    getAddOns?.reduce((acc, addOnCode) => {
      const addOnPrice = getPrice(addOnCode);
      return acc + addOnPrice;
    }, 0) || 0;

  const getServiceTaxAmt = (addOnsTotal + getPlan?.grossPrem) * 0.08;

  const getTotalPrice = () => {
    const totalPrice =
      addOnsTotal + getPlan?.grossPrem + getServiceTaxAmt + getPlan.stampDuty;
    dispatch(setTotalPrice(totalPrice));
    return totalPrice;
  };

  return {
    getServiceTaxAmt: formatNumber(getServiceTaxAmt),
    getTotalPrice: formatNumber(getTotalPrice()),
    selectedPlan,
    addOnData,
    getPlan,
    getAddOns,
    getCoveragePeriod,
    benefitCodeToTitle,
    getPrice,
    steps,
    moveNext,
    t,
    closeModal,
    setCloseModal,
    isMobileView: isIpadView,
    coverType: t(policyTypeLabel?.[coverType])
  };
};

export default PaymentBoxLogic;
