import React, { useEffect } from 'react';

import { setIsUpdate } from 'components/CoverageOption/Logic/CoverageOption.reducer';
import { MainContainer } from 'components/UIComponent';
import useCleverTapPush from 'hooks/CleverTapData';
import DataLayer from 'hooks/DataLayerPush';
import { useAppDispatch, useAppSelector } from 'hooks/ReduxHook';
import { useStepper } from 'hooks/Stepper';

function PaymentStatus() {
  const queryParameters = new URLSearchParams(window.location.search);
  const status = queryParameters.get('status');
  const { insurance } = useAppSelector((state) => {
    return {
      insurance: state.checkout.insurance
    };
  });

  const { currentRoute, moveToPage, redirectTo } = useStepper();

  const { pushData } = useCleverTapPush(currentRoute);
  const { sendPageData } = DataLayer(currentRoute, status);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setIsUpdate(true));
    if (status === 'success') {
      pushData();
    }
    sendPageData();
  }, [status]);

  const retry = () => {
    dispatch(setIsUpdate(false));
    moveToPage('/checkout');
  };

  return (
    <MainContainer
      isLoading={status}
      modalProps={{
        type: status
      }}
      refId={insurance?.id}
      handleClose={
        status === 'success'
          ? () => redirectTo(process.env.REACT_APP_POS_URL)
          : () => retry()
      }
    />
  );
}

export default PaymentStatus;
