import {
  Allianz,
  Etiqa,
  RHB,
  Tune,
  Zurich,
  ZurichTakaful
} from 'assets/images';

export const logoArray = {
  13: {
    name: 'ALLIANZ GENERAL INSURANCE COMPANY (MALAYSIA) BERHAD',
    logo: Allianz
  },
  18: { name: 'ZURICH GENERAL INSURANCE MALAYSIA BERHAD', logo: Zurich },
  97: { name: 'ZURICH GENERAL TAKAFUL MALAYSIA BERHAD', logo: ZurichTakaful },
  96: { name: 'ETIQA GENERAL TAKAFUL BERHAD', logo: Etiqa },
  21: { name: 'RHB INSURANCE BERHAD', logo: RHB },
  40: { name: 'TUNE PROTECT MALAYSIA', logo: Tune }
};

export const coverCodeArr = ['01', '02', '03'];

export const policyTypeLabel = {
  '01': 'comprehensive',
  '02': 'third_party',
  '03': 'third_party_ft'
};

export const planTypes = {
  comprehensive: 'Comprehensive',
  third_party: 'Third Party',
  third_party_ft: 'Third Party Fire & Theft'
};

export const vhlTypeLabel = {
  CVP: 'car',
  CVM: 'motorcycle'
};

export const links = {
  TNC: 'https://www.pos.com.my/legal/terms-and-conditions-motor-insurance',
  PRIVACYSTATEMENT: 'https://www.pos.com.my/legal/privacy-statement',
  MOREINFOZURICH:
    'https://www.zurich.com.my/en/customer-hub/show-me-more-info/personal-data-protection-notice'
};

export const benefitsMap = {
  CVP: {
    '01': {
      13: '1',
      18: '7',
      97: '13',
      40: '19',
      21: '23',
      96: '27'
    },
    '02': {
      13: '2',
      18: '8',
      97: '14',
      21: '24'
    },
    '03': {
      13: '3',
      18: '9',
      97: '15',
      40: '20',
      21: '24',
      96: '28'
    }
  },
  CVM: {
    '01': {
      13: '4',
      18: '10',
      97: '16',
      40: '21',
      21: '25',
      96: '29'
    },
    '02': {
      13: '5',
      18: '11',
      97: '17',
      40: '22',
      21: '26'
    },
    '03': {
      13: '6',
      18: '12',
      97: '18',
      96: '28'
    }
  }
};

export const benefitsLabel = {
  0: ['Not Available'],
  1: [
    'allianze_car_comprehensive_1',
    'allianze_car_comprehensive_2',
    'allianze_car_comprehensive_3'
  ],
  2: ['allianze_car_third_party_1', 'allianze_car_third_party_2'],
  3: [
    'allianze_car_fire_theft_1',
    'allianze_car_fire_theft_2',
    'allianze_car_fire_theft_3'
  ],
  4: [
    'allianze_motor_comprehensive_1',
    'allianze_motor_comprehensive_2',
    'allianze_motor_comprehensive_3',
    'allianze_motor_comprehensive_4',
    'allianze_motor_comprehensive_5',
    'allianze_motor_comprehensive_6',
    'allianze_motor_comprehensive_7',
    'allianze_motor_comprehensive_8'
  ],
  5: [
    'allianze_motor_third_party_1',
    'allianze_motor_third_party_2',
    'allianze_motor_third_party_3',
    'allianze_motor_third_party_4'
  ],
  6: [
    'allianze_motor_fire_theft_1',
    'allianze_motor_fire_theft_2',
    'allianze_motor_fire_theft_3'
  ],
  7: [
    'zurich_g_car_comprehensive_1',
    'zurich_g_car_comprehensive_2',
    'zurich_g_car_comprehensive_3',
    'zurich_g_car_comprehensive_4',
    'zurich_g_car_comprehensive_5',
    'zurich_g_car_comprehensive_6',
    'zurich_g_car_comprehensive_7'
  ],
  8: ['zurich_g_car_third_party_1', 'zurich_g_car_third_party_2'],
  9: [
    'zurich_g_car_fire_theft_1',
    'zurich_g_car_fire_theft_2',
    'zurich_g_car_fire_theft_3'
  ],
  10: [
    'zurich_g_motor_comprehensive_1',
    'zurich_g_motor_comprehensive_2',
    'zurich_g_motor_comprehensive_3',
    'zurich_g_motor_comprehensive_4',
    'zurich_g_motor_comprehensive_5',
    'zurich_g_motor_comprehensive_6'
  ],
  11: ['zurich_g_motor_third_party_1', 'zurich_g_motor_third_party_2'],
  12: [
    'zurich_g_motor_fire_theft_1',
    'zurich_g_motor_fire_theft_2',
    'zurich_g_motor_fire_theft_3'
  ],
  13: [
    'zurich_t_car_comprehensive_1',
    'zurich_t_car_comprehensive_2',
    'zurich_t_car_comprehensive_3',
    'zurich_t_car_comprehensive_4',
    'zurich_t_car_comprehensive_5',
    'zurich_t_car_comprehensive_6',
    'zurich_t_car_comprehensive_7'
  ],
  14: ['zurich_t_car_third_party_1', 'zurich_t_car_third_party_2'],
  15: [
    'zurich_t_car_fire_theft_1',
    'zurich_t_car_fire_theft_2',
    'zurich_t_car_fire_theft_3'
  ],
  16: [
    'zurich_t_motor_comprehensive_1',
    'zurich_t_motor_comprehensive_2',
    'zurich_t_motor_comprehensive_3',
    'zurich_t_motor_comprehensive_4',
    'zurich_t_motor_comprehensive_5',
    'zurich_t_motor_comprehensive_6'
  ],
  17: ['zurich_t_motor_third_party_1', 'zurich_t_motor_third_party_2'],
  18: [
    'zurich_t_motor_fire_theft_1',
    'zurich_t_motor_fire_theft_2',
    'zurich_t_motor_fire_theft_3'
  ],
  19: [
    'tune_car_comprehensive_1',
    'tune_car_comprehensive_2',
    'tune_car_comprehensive_3',
    'tune_car_comprehensive_4',
    'tune_car_comprehensive_5',
    'tune_car_comprehensive_6',
    'tune_car_comprehensive_7'
  ],
  20: [
    'tune_car_fire_theft_1',
    'tune_car_fire_theft_2',
    'tune_car_fire_theft_3'
  ],
  21: [
    'tune_motor_comprehensive_1',
    'tune_motor_comprehensive_2',
    'tune_motor_comprehensive_3',
    'tune_motor_comprehensive_4',
    'tune_motor_comprehensive_5'
  ],
  22: ['tune_motor_third_party_1', 'tune_motor_third_party_2'],
  23: [
    'rhb_car_comprehensive_1',
    'rhb_car_comprehensive_2',
    'rhb_car_comprehensive_3',
    'rhb_car_comprehensive_4'
  ],
  24: ['rhb_car_fire_theft_1', 'rhb_car_fire_theft_2'],
  25: [
    'rhb_motor_comprehensive_1',
    'rhb_motor_comprehensive_2',
    'rhb_motor_comprehensive_3',
    'rhb_motor_comprehensive_4'
  ],
  26: [
    'rhb_motor_fire_theft_1',
    'rhb_motor_fire_theft_2',
    'rhb_motor_fire_theft_3'
  ],
  27: [
    'etiqa_car_comprehensive_1',
    'etiqa_car_comprehensive_2',
    'etiqa_car_comprehensive_3',
    'etiqa_car_comprehensive_4',
    'etiqa_car_comprehensive_5',
    'etiqa_car_comprehensive_6',
    'etiqa_car_comprehensive_7',
    'etiqa_car_comprehensive_8'
  ],
  28: [
    'etiqa_car_fire_theft_1',
    'etiqa_car_fire_theft_2',
    'etiqa_car_fire_theft_3',
    'etiqa_car_fire_theft_4'
  ],
  29: [
    'etiqa_motor_comprehensive_1',
    'etiqa_motor_comprehensive_2',
    'etiqa_motor_comprehensive_3',
    'etiqa_motor_comprehensive_4',
    'etiqa_motor_comprehensive_5'
  ]
};

export const addOnsList = [
  {
    benCode: 'WSC',
    benDesc: 'BREAKAGE OF GLASS IN WINDSCREEN',
    sumInsured: 500
  },
  {
    benCode: 'CVPALL01FLD',
    benDesc: 'Flood, Windstorm & Typhoon'
  },
  {
    benCode: 'ALLD',
    benDesc: 'ALL DRIVERS (INDIVIDUAL)'
  },
  {
    benCode: 'LLTP',
    benDesc: 'LEGAL LIABILITY TO PASSENGERS'
  },
  {
    benCode: 'LLOP',
    benDesc: 'LEGAL LIABILITY OF PASSENGERS FOR ACTS OF NEGLIGENCE'
  }
];

export const benefitCodeToTitle = {
  CVPALL01FLD: 'addon_1',
  CVMALL01FLD: 'addon_11',
  ALLD: 'addon_2',
  ALD: 'addon_2',
  AND: 'addon_3',
  WSC: 'addon_4',
  LLOP: 'addon_5',
  LLTP: 'addon_6',
  UNTOW01: 'addon_7',
  UNTOW02: 'addon_7',
  EHAILING: 'addon_8',
  CVPP401C002: 'addon_9',
  CVPP4CART: 'addon_10',
  CVPP1CART: 'addon_12',
  CVPALL02LLTP: 'addon_6',
  CVPALL02LLOP: 'addon_5'
};

export const benefitCodeToInfo = {
  ALLD: 'allDrivers',
  ALD: 'allDrivers',
  WSC: 'wsc',
  CVPP1CART: 'cvpp1cart',
  CVPP4CART: 'cvpp4cart',
  CVPALL01FLD: 'flood',
  CVMALL01FLD: 'flood',
  LLOP: 'llop',
  LLTP: 'lltp',
  AND: 'and',
  CVPALL02LLOP: 'llop',
  CVPALL02LLTP: 'lltp',
  EHAILING: 'ehailing',
  CVPP101C002: 'waiver',
  UNTOW01: 'untow2',
  UNTOW02: 'untow2',
  CVPP401C002: 'waiver'
};

export const addOnListTitle = {
  CVPALL01FLD: 'Special Peril Coverage',
  CVMALL01FLD: 'Flood, Windstorm & Typhoon',
  ALLD: 'Unlimited Drivers',
  ALD: 'Unlimited Drivers',
  AND: 'Additional Drivers',
  WSC: 'Windscreen',
  LLOP: 'Legal Liability of Passengers for Negligent Acts',
  LLTP: 'Legal Liability to Passengers',
  UNTOW01: 'Unlimited Towing',
  UNTOW02: 'Unlimited Towing',
  EHAILING: 'Private Hire Car (E-hailing)',
  CVPP401C002: 'Waiver of Betterment',
  CVPP4CART: 'Compensation for Assessed Repair Time',
  CVPP1CART: 'Compensation for Assessed Repair Time',
  CVPALL02LLTP: 'Legal Liability to Passengers',
  CVPALL02LLOP: 'Legal Liability of Passengers for Negligent Acts'
};

export const maritalStatusLabels = {
  S: 'v_f_marital_s',
  M: 'v_f_marital_m'
};

export const maleFemaleLabels = {
  M: 'Male',
  F: 'Female'
};

export const errorCodeMessages = {
  '001': 'error1',
  '008': 'error2',
  '000': 'error3',
  '077': 'error4',
  '003': 'error3',
  '009': 'error10',
  '031': 'error3',
  '033': 'error11',
  '034': 'error10',
  '035': 'error4',
  3: 'error2',
  433: 'error13',
  434: 'error12',
  435: 'error14'
};
