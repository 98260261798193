/** @jsxImportSource theme-ui */
import React from 'react';

import { style } from './Button.style';
import { Button } from 'components/UIComponent';
import DataLayer from 'hooks/DataLayerPush';
import useMobileSize from 'hooks/IsMobile';
import { useAppDispatch } from 'hooks/ReduxHook';
import { Translate } from 'hooks/Translation';

const ButtonUi = ({
  children,
  form,
  gtmData,
  handleClick,
  isDisabled,
  label,
  twoButtons,
  type
}) => {
  const { sendEventData } = DataLayer();
  const { isMobileView } = useMobileSize();
  const { t } = Translate();
  const dispatch = useAppDispatch();
  const close = () => {
    dispatch({ type: 'resetModal' });
  };

  const handleOneBtnClick = (e) => {
    if (gtmData) {
      sendEventData(e);
    }
    if (handleClick) {
      return handleClick();
    }
    close();
  };

  const handleTwoBtnClick = (e) => {
    if (twoButtons?.gtmData) {
      sendEventData(e, twoButtons.gtmData);
    }
    if (twoButtons?.handleClick) {
      twoButtons.handleClick();
    }
  };

  return (
    <div sx={style.container}>
      {twoButtons?.value ? (
        <Button
          type={twoButtons.type}
          form={twoButtons.form}
          sx={style.button(isMobileView)}
          onClick={handleTwoBtnClick}
          disabled={twoButtons.disabled}
          data-event={twoButtons?.gtmData?.event}
          data-event-category={twoButtons?.gtmData?.eventCategory}
          data-event-action={twoButtons?.gtmData?.eventAction}
          data-event-label={twoButtons?.gtmData?.eventLabel}
        >
          {twoButtons.text}
        </Button>
      ) : null}
      <Button
        type={type}
        form={form}
        sx={style.button(isMobileView)}
        onClick={handleOneBtnClick}
        disabled={isDisabled}
        data-event={gtmData?.event}
        data-event-category={gtmData?.eventCategory}
        data-event-action={gtmData?.eventAction}
        data-event-label={gtmData?.eventLabel}
      >
        {children || t(label)}
      </Button>
    </div>
  );
};

export default ButtonUi;
