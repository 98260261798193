/** @jsxImportSource theme-ui */
import React, { useState } from 'react';

import { style } from './RadioBoxContainer.style';
import { Checked, UnChecked } from 'assets/images';
import DataLayer from 'hooks/DataLayerPush';
import { useStepper } from 'hooks/Stepper';
import { Translate } from 'hooks/Translation';

function RadioBoxContainer({
  defaultChecked,
  divClick,
  gtmData,
  handleChangeEvent,
  id,
  index,
  info,
  title,
  value
}) {
  const { t } = Translate();
  const [check, setCheck] = useState(false);
  const { currentRoute } = useStepper();
  const { sendEventData } = DataLayer(currentRoute);

  const handleChange = (event) => {
    event.stopPropagation();
    sendEventData(event);
    handleChangeEvent(index);
    setCheck(!check);
  };

  return (
    <div sx={style.radioBoxContainer}>
      <img src={defaultChecked ? Checked : UnChecked} sx={style.checkBox} />
      <div>
        <input
          data-event={gtmData.event}
          data-event-category={gtmData.category}
          data-event-action={gtmData.action}
          data-event-label={gtmData.label}
          type="radio"
          id={id}
          value={value}
          onChange={(event) => handleChange(event)}
          defaultChecked={defaultChecked}
          sx={{ display: 'none' }}
        />
        <label htmlFor={id} onClick={divClick}>
          <div sx={style.title}>{title}</div>
          <p>{info}</p>
        </label>
      </div>
    </div>
  );
}

export default RadioBoxContainer;
