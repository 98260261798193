import { useEffect } from 'react';

import {
  selectedData,
  setLowestQuote,
  setPlanData,
  setSelectedPlan
} from 'components/Plans/Logic/Plans.reducer';
import {
  benefitsMap,
  logoArray,
  planTypes,
  policyTypeLabel
} from 'constants/label';
import useCleverTapPush from 'hooks/CleverTapData';
import DataLayer from 'hooks/DataLayerPush';
import { useAppDispatch, useAppSelector } from 'hooks/ReduxHook';
import { useStepper } from 'hooks/Stepper';
import { Translate } from 'hooks/Translation';
import { useGetPremiumMutation } from 'services/api/services';
import { quotationPlansPageview } from 'services/decorator/pushData/selector';
import {
  benefitListDecorator,
  premiumRequest
} from 'services/decorator/request';
import { setModalProps, setShowModal } from 'store/modal';
import {
  capitalizeFirstLetter,
  sliceFirstTwoWords,
  sliceFirstWord
} from 'utils/helper';

const PlansLogic = () => {
  const dispatch = useAppDispatch();
  const premium = useAppSelector((state) => state.premium);
  const { selectSumInsured } = useAppSelector((state) => state.base);
  const premiumData = useAppSelector(premiumRequest);
  const plans = useAppSelector((state) => state.plans);
  const vhlType = useAppSelector((state) => state.vhlType);
  const plansData = useAppSelector(quotationPlansPageview);
  const { modal, showModal } = useAppSelector((state) => state.modal);
  const { currentRoute, moveNext } = useStepper();
  const { pushClick } = useCleverTapPush(currentRoute);
  const { sendPageData } = DataLayer(currentRoute);
  const [getPremium, { isLoading }] = useGetPremiumMutation();
  const { sumInsuredOption } = useAppSelector((state) => state.vix);
  const { t } = Translate();

  useEffect(() => {
    const lowestQuote = getLowestQuote();
    pushClick(
      null,
      {
        ...plansData,
        'Lowest quote': lowestQuote?.price,
        'Lowest quote insurance provider': lowestQuote?.compName
      },
      'QuotationPlans_pageview'
    );
  }, []);

  const getBenefits = (plan) => {
    const { compCode, coverCode, vehTypeCode } = plan;
    return benefitsMap?.[vehTypeCode]?.[coverCode]?.[compCode];
  };

  const handleClick = (id) => {
    dispatch(setShowModal(true));
    dispatch(setModalProps({ updateType: id }));
  };

  const sort = (insurers) => {
    if (!insurers || typeof insurers[Symbol.iterator] !== 'function') {
      console.error('Insurers is not iterable');
      return [];
    }
    const sortedInsurers = [...insurers];
    switch (plans.sort) {
      case 'price_up':
        return sortedInsurers.sort((a, b) => a.grossPrem - b.grossPrem);
      case 'price_down':
        return sortedInsurers.sort((a, b) => b.grossPrem - a.grossPrem);
      case 'default':
        return insurers;
    }
  };

  const selectedPlan = async (e, plan) => {
    dispatch(setModalProps({ type: 'api' }));
    dispatch(setSelectedPlan(plan?.compCode));
    pushClick(e, {
      'Policy type': planTypes?.[policyTypeLabel?.[plan?.coverCode]],
      'Policy premium': plan?.grossPrem,
      'Insurance provider': logoArray?.[plan?.compCode]?.name,
      Clickname: 'quote plan'
    });
    sendPageData({
      items: [
        {
          item_id: plan.compCode,
          item_name: `${sliceFirstTwoWords(logoArray?.[plan.compCode]?.name)} ${
            planTypes?.[policyTypeLabel?.[plan.coverCode]]
          }`,
          item_brand: sliceFirstWord(logoArray?.[plan.compCode]?.name),
          item_category_2: capitalizeFirstLetter(vhlType.type),
          item_category_3: planTypes?.[policyTypeLabel?.[plan.coverCode]]
        }
      ]
    });
    if (
      plans.planData?.[plan?.coverCode]?.[selectSumInsured]?.[plan?.compCode]
    ) {
      return moveNext();
    }
    try {
      const {
        data: { item }
      } = await getPremium({
        ...premiumData,
        compCode: plan.compCode,
        sumInsured: sumInsuredOption[selectSumInsured],
        addBenData: {
          item: benefitListDecorator(plan.benefitList, plan.compCode)
        }
      }).unwrap();
      dispatch(
        setPlanData({
          coverCode: plan.coverCode,
          data: { [plan.compCode]: item[0]?.addBenData?.item || [] },
          selectSumInsured
        })
      );
      dispatch(selectedData(item[0]));
      moveNext();
    } catch (error) {
      console.log(error);
    }
  };

  const getInsurers = () =>
    sort(premium?.[plans.coverType]?.[selectSumInsured] || []);

  const getFilteredInsurers = () =>
    getInsurers()?.filter((element) => element.respCode === '1');

  const closeModal = () => dispatch(setShowModal(false));

  const getLowestQuote = () => {
    const insurers = getFilteredInsurers();
    const lowestQuote = insurers
      ?.map((insurer) => ({
        price: insurer.grossPrem,
        compName: insurer.compName
      }))
      ?.sort((a, b) => a.price - b.price)[0];
    dispatch(setLowestQuote(lowestQuote));
    return lowestQuote;
  };

  return {
    insurers: getFilteredInsurers(),
    getBenefits,
    sort,
    handleClick,
    showModal,
    closeModal,
    isLoading,
    selectedPlan,
    modalProps: modal,
    t,
    coverType: plans.coverType
  };
};

export default PlansLogic;
