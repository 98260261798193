import React from 'react';

import './Toggle.scss';

function InputToggle({ check, label, toggle }) {
  return (
    <>
      <div className="toggleComponent">
        {label && <span className="m-3 label">{label}</span>}
        <label className="switch">
          <input
            data-event={'motor_enable_select_all_recommendation'}
            data-event-category={'Premium Details'}
            data-event-action={`${
              check ? 'Enable' : 'Disable'
            } Select All Recommendations`}
            data-event-label={'Select All Recommendations'}
            type="checkbox"
            onChange={(e) => toggle(e)}
            checked={check}
          />
          <span className="slider round"></span>
        </label>
      </div>
    </>
  );
}

export default InputToggle;
