/** @jsxImportSource theme-ui */
import React from 'react';

import { style } from './ModalContainer.style';
import { Modal } from 'components/UIComponent';

const ModalContainer = ({ children, handleHide, show }) => {
  return (
    <Modal centered show={show} onHide={handleHide}>
      <div sx={style.container}>{children}</div>
    </Modal>
  );
};

export default ModalContainer;
