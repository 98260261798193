import React from 'react';

import './Home.scss';
import {
  AddOnsContainer,
  AdvantageContainer,
  AnimArrow,
  BrandList,
  CompContainer,
  Faq,
  ProcessContainer,
  StepsContainer,
  VhlTypeContainer
} from 'pages';

function Home() {
  return (
    <>
      <VhlTypeContainer />
      <BrandList />
      <AnimArrow />
      <AdvantageContainer />
      <StepsContainer />
      <CompContainer />
      <AddOnsContainer />
      <ProcessContainer />
      <Faq />
    </>
  );
}

export default Home;
