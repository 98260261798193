import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './App.css';
import 'pages/styles.scss';
import { BottomFooter, Footer, Header, StepperLayout } from './components';
import { ErrorPage } from './pages';
import { Container } from 'components/UIComponent';
import { useCleverTap } from 'hooks/CleverTapManager';
import { useGoogleTagManager } from 'hooks/GoogleTagManager';
import { useAppDispatch } from 'hooks/ReduxHook';
import { useRelicData } from 'hooks/RelicData';
import useRouteSequence from 'hooks/RouteJourney';
import { routes, stepperRoutes } from 'pages/Routes';
import { signingIn } from 'services/api/auth';

export const App = () => {
  const dispatch = useAppDispatch();
  useGoogleTagManager();
  useCleverTap(3);
  useRelicData();
  useEffect(() => {
    if (window.location.pathname === '/') {
      dispatch({ type: 'reset' });
    }
    signingIn();
  }, []);
  return (
    <Router>
      <Container>
        <Header />
        <Routes>
          {routes.map((item) => {
            const RouteComponent = item.component;
            return (
              <Route
                exact={item.exact}
                key={`${item.route}`}
                path={item.route}
                element={
                  <RouteGuard>
                    <RouteComponent />
                  </RouteGuard>
                }
              />
            );
          })}
          <Route element={<StepperLayout />}>
            {stepperRoutes.map((item) => {
              const RouteComponent = item.component;
              return (
                <React.Fragment key={`${item.route}`}>
                  <Route
                    exact={item.exact}
                    key={`${item.route}`}
                    path={item.route}
                    element={
                      <RouteGuard>
                        <RouteComponent />
                      </RouteGuard>
                    }
                  />
                </React.Fragment>
              );
            })}
          </Route>
          <Route path="*" element={<ErrorPage />} />
        </Routes>
        <Footer />
        <BottomFooter />
      </Container>
    </Router>
  );
};

const RouteGuard = ({ children }) => {
  useRouteSequence();
  return children;
};
