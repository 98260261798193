import React from 'react';

import './Checkout.scss';
import CheckoutLogic from './Logic/Checkout.logic';
import { PaymentBox } from 'components';
import { MainContainer } from 'components/UIComponent';
import { links, maritalStatusLabels } from 'constants/label';

function Checkout() {
  const {
    confirmVariation,
    getIcFormat,
    handleError,
    isLoading,
    isMobileView,
    modal,
    pdfUrl,
    referralCode,
    selectedPlan,
    sendFinalPlan,
    t,
    vehicleForm,
    vix
  } = CheckoutLogic();
  return (
    <MainContainer modalProps={modal} handleClose={handleError}>
      <div className="checkout">
        <div className="options">
          <div className="information">
            <div className="title">
              <span className="medium subBlueTitle">
                {t('confirm_customer')}{' '}
              </span>
            </div>
            <div className="section">
              <div className="section">
                <small className="small">{t('full_name')}</small>
                <div className="medium">{vehicleForm.fullName}</div>
              </div>
              <div className="section">
                <small className="small">
                  {t('owner')} {vehicleForm.newIc ? 'NRIC' : 'Passport ID'}
                </small>
                <div className="medium">
                  {vehicleForm.newIc
                    ? getIcFormat(vehicleForm.newIc)
                    : vehicleForm?.passportNo}
                </div>
              </div>
              <small className="small">{t('current_address')}</small>
              <div className="address">
                <div className="medium">{vehicleForm.address}</div>
                <div className="medium">{`${vehicleForm.city} ${vehicleForm.postcode}`}</div>
                <div className="medium">{vehicleForm.state}</div>
              </div>
            </div>
            <div className="section">
              <small className="small">{t('mobile')}</small>
              <div className="medium">+60-{vehicleForm.mobileNumber}</div>
            </div>
            <div className="section">
              <small className="small">{t('email')}</small>
              <div className="medium">{vehicleForm.email}</div>
            </div>
            <div className="section">
              <small className="small">{t('marital_status')}</small>
              <div className="medium">
                {t(maritalStatusLabels[vehicleForm?.maritalStatus])}
              </div>
            </div>
            <div className="section">
              <small className="small">{t('vehicle_reg')}</small>
              <div className="medium">{vix?.vehRegNo}</div>
            </div>
            <div className="section">
              <small className="small">{t('vehicle_details')}</small>
              <div className="medium">
                {confirmVariation?.desc} {vix?.makeYear}
              </div>
            </div>
            {referralCode ? (
              <div className="section">
                <small className="small">Referral Code</small>
                <div className="medium">{referralCode}</div>
              </div>
            ) : null}
          </div>
        </div>
        <PaymentBox
          handleClick={sendFinalPlan}
          isLoading={isLoading}
          btnLabel={'pay'}
          pdfUrl={pdfUrl}
          gtmData={{
            event: 'motor_submit_order',
            eventCategory: 'Checkout',
            eventAction: 'Submit Order',
            eventLabel: 'Order'
          }}
        />
      </div>
      {isMobileView ? (
        <div className={'declaration small'}>
          {t('pay_info')}
          <span className="subBlueTitle">
            <a href={links.TNC} target="_blank" rel="noreferrer">
              {` ${t('terms')}`}
            </a>
          </span>
          , {`${t('pay_info_2')}`}
          <span className="subBlueTitle">
            <a href={pdfUrl} target="_blank" rel="noreferrer">
              {` ${t('product_disclosure')} `}
            </a>
          </span>
          {selectedPlan === '18' || selectedPlan === '97' ? (
            <span>
              ,
              <a
                className="subBlueTitle"
                href={links.MOREINFOZURICH}
                target="_blank"
                rel="noreferrer"
              >
                {t('product_disclosure2')}
              </a>
            </span>
          ) : (
            ''
          )}
          {t('pay_info_3')}
        </div>
      ) : null}
    </MainContainer>
  );
}

export default Checkout;
