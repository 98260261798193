import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { setVhlType } from '../../VhlTypeContainer/Logic/VhlTypeContainer.reducer';
import { setFieldValue } from './VehicleForm.reducer';
import { vhlTypeLabel } from 'constants/label';
import { validationRules } from 'constants/validationRules';
import DataLayer from 'hooks/DataLayerPush';
import { useAppDispatch, useAppSelector } from 'hooks/ReduxHook';
import { useStepper } from 'hooks/Stepper';
import { Translate } from 'hooks/Translation';
import { useCheckVehicleMutation } from 'services/api/services';
import { vixNcd } from 'services/decorator/request';
import { setNewExpiryDate } from 'store/base';
import { setModalProps, setShowModal } from 'store/modal';
import { setVehicleData } from 'store/response/vix';
import { findStateAndCity } from 'utils/helper';

export const VehicleFormLogic = () => {
  const { sendBtnData } = DataLayer();
  const useFormMethod = useForm({ mode: 'all' });
  const { t } = Translate();
  const rules = validationRules(t);
  const {
    formState: { errors },
    register
  } = useFormMethod;
  const [formError, setFormError] = useState(null);
  const [emptyMobile, setEmptyMobile] = useState(0);
  const [isPassport, setIsPassport] = useState(false);
  const divRef = useRef(null);
  const dispatch = useAppDispatch();
  const [checkVehicle, { error, isLoading }] = useCheckVehicleMutation();
  const { moveNext } = useStepper();
  const { modal } = useAppSelector((state) => state.modal);

  useEffect(() => {
    localStorage.removeItem('persist:insurance');
    setTimeout(() => {
      dispatch({ type: 'reset' });
    }, 100);
  }, []);

  const onSubmit = async (vixData) => {
    sendBtnData({
      event: 'submit_vehicle_details',
      event_category: 'Home',
      event_action: 'Submit Vehicle Details',
      event_label: 'Vehicle Details'
    });
    const cityState = findStateAndCity(vixData.postcode);
    dispatch(
      setFieldValue({
        ...vixData,
        city: cityState?.city,
        state: cityState?.state
      })
    );
    try {
      const response = await checkVehicle(vixNcd(vixData)).unwrap();
      const { data } = response;
      dispatch(setVehicleData(data));
      dispatch(setVhlType(vhlTypeLabel[data.vehTypeCode]));
      dispatch(setNewExpiryDate(data.expiryDate));
      moveNext();
    } catch (err) {
      dispatch(
        setModalProps({
          type: 'error',
          code: err.data?.errors?.[0]?.code
        })
      );
      dispatch(setShowModal(true));
    }
  };

  const onError = (error) => {
    setFormError(error);
  };

  const closeModal = () => {
    dispatch(setShowModal(false));
    dispatch({ type: 'resetModal' });
  };

  const callBack = ({ type_id: typeId }) => setIsPassport(typeId === '2');

  return {
    formError,
    onSubmit,
    onError,
    useFormMethod,
    divRef,
    emptyMobile,
    setEmptyMobile,
    isLoading,
    register,
    errors,
    t,
    rules,
    callBack,
    modalProps: modal?.type ? modal : { type: 'api' },
    isPassport,
    closeModal
  };
};

export default VehicleFormLogic;
