import { useCallback } from 'react';
import TagManager from 'react-gtm-module';

import { useAppSelector } from './ReduxHook';
import { gtmVixData } from 'services/decorator/pushData/selector';
import { routeToEventMap } from 'utils/routeToEventMap';

const useDataLayerPush = (currentRoute, status) => {
  const config = routeToEventMap?.[currentRoute];
  const gtmData = config ? useAppSelector(config.gtm) : null;
  const gtmBtnData = useAppSelector(gtmVixData);
  const dataLayerPush = useCallback((eventData) => {
    TagManager.dataLayer({ dataLayer: eventData });
  }, []);

  const sendEventData = useCallback(
    (e, data = {}) => {
      const target = e?.currentTarget;
      const eventData = {
        event: target?.getAttribute('data-event') || data.event,
        event_category:
          target?.getAttribute('data-event-category') || data.event_category,
        event_action:
          target?.getAttribute('data-event-action') || data.event_action,
        event_label:
          target?.getAttribute('data-event-label') || data.event_label,
        ...data
      };

      dataLayerPush(eventData);
    },
    [dataLayerPush]
  );

  const sendBtnData = useCallback(
    (data = {}) => {
      dataLayerPush({ ...data, ...gtmBtnData });
    },
    [dataLayerPush]
  );

  const sendPageData = useCallback(
    (data = {}) => {
      if (config) {
        if (currentRoute === '/payment-status' && status) {
          return dataLayerPush({
            ...gtmData,
            ...config.gtmEvent,
            event: status === 'success' ? 'purchase' : 'purchase_failure',
            event_action:
              status === 'success' ? 'Purchase' : 'Purchase Failure',
            ...data
          });
        }
        dataLayerPush({ ...gtmData, ...config.gtmEvent, ...data });
      }
    },
    [dataLayerPush]
  );

  return { sendEventData, sendPageData, sendBtnData };
};

export default useDataLayerPush;
