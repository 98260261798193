import React from 'react';

import './Plans.scss';
import PlansLogic from './Logic/Plans.logic';
import { PlanBox, PolicyDropdown } from 'components';
import { ExclamationCircle, MainContainer } from 'components/UIComponent';

function Plans() {
  const {
    closeModal,
    coverType,
    getBenefits,
    handleClick,
    insurers,
    isLoading,
    modalProps,
    selectedPlan,
    t
  } = PlansLogic();

  return (
    <MainContainer
      isLoading={isLoading}
      handleClose={closeModal}
      modalProps={modalProps}
    >
      <PolicyDropdown handleClick={handleClick} />
      {insurers?.length > 0 ? (
        insurers?.map((insurer, index) => {
          const benefits = getBenefits(insurer);
          return (
            <PlanBox
              plans={insurers?.length}
              key={`${coverType}${insurer.compCode}`}
              benefits={benefits}
              plan={insurer}
              id={index}
              selectedPlan={selectedPlan}
            />
          );
        })
      ) : (
        <div className="addons-not-match text-center">
          <ExclamationCircle
            color={'#5698FC'}
            size={60}
            style={{
              border: '0',
              borderRadius: '0%',
              backgroundColor: '#E7F6FF',
              padding: '10px'
            }}
          />
          <div className="label">{t('error7')}</div>
        </div>
      )}
    </MainContainer>
  );
}

export default Plans;
