import { useEffect, useState } from 'react';

import {
  resetSelectedPlan,
  setCoverType
} from 'components/Plans/Logic/Plans.reducer';
import { coverCodeArr } from 'constants/label';
import useCleverTapPush from 'hooks/CleverTapData';
import DataLayer from 'hooks/DataLayerPush';
import { useAppDispatch, useAppSelector } from 'hooks/ReduxHook';
import { useStepper } from 'hooks/Stepper';
import { useGetPremiumMutation } from 'services/api/services';
import { premiumRequest } from 'services/decorator/request';
import { setOldCar } from 'store/base';
import { setModalProps, setShowModal } from 'store/modal';
import { resetLoading, setLoading, setPremium } from 'store/response/premium';
import { setSumInsuredOption, setVariation } from 'store/response/vix';
import { isNullOrUndefined, isObjectEmpty, oldCar } from 'utils/helper';

const UserVerificationLogic = () => {
  const dispatch = useAppDispatch();
  const { isLoading, modal, sumInsuredOption, vehicleType, vix } =
    useAppSelector((state) => {
      return {
        sumInsuredOption: state.vix.sumInsuredOption,
        vix: state?.vix?.vix,
        isLoading: state.premium.isLoading,
        modal: state.modal.modal,
        vehicleType: state.vhlType.type
      };
    });
  const [loadingCode, setLoadingCode] = useState();
  const { currentRoute, moveNext, movePrev } = useStepper();
  const { pushClick, pushData } = useCleverTapPush(currentRoute);
  const { sendPageData } = DataLayer(currentRoute);
  const [getPremium, { error }] = useGetPremiumMutation();
  const premiumReq = useAppSelector(premiumRequest);

  useEffect(() => {
    if (isNullOrUndefined(vix) || isObjectEmpty(vix)) return movePrev();
    dispatch(setVariation(vix?.variation?.[0]));
    dispatch(resetSelectedPlan());
    dispatch(
      setSumInsuredOption({
        sumInsured: vix?.variation?.[0]?.sumInsured,
        reducedSumInsured: (vix?.variation?.[0]?.sumInsured * 0.9).toString()
      })
    );
    dispatch({ type: 'resetModal' });
    pushData();
    sendPageData();
  }, []);

  const confirmVariation = (e) => {
    dispatch(setVariation(vix?.variation?.[e.target?.value]));
    dispatch(
      setSumInsuredOption({
        sumInsured: vix?.variation?.[e.target?.value]?.sumInsured,
        reducedSumInsured: (
          vix?.variation?.[e.target?.value]?.sumInsured * 0.9
        ).toString()
      })
    );
  };

  const next = async (e) => {
    dispatch(setModalProps({ type: 'api' }));
    dispatch(setOldCar(oldCar(vix.makeYear)));
    pushClick(e);
    const coverCodeList = checkElligibleCoverCodes();
    const sumInsuredTypes = ['sumInsured', 'reducedSumInsured'];

    dispatch({ type: 'loadingAll' });
    for (const [index, code] of coverCodeList.entries()) {
      setLoadingCode(index);
      for (const selectSumInsured of sumInsuredTypes) {
        const sumInsuredValue = sumInsuredOption?.[selectSumInsured] || 0;
        try {
          const response = await getPremium({
            ...premiumReq,
            coverCode: code,
            sumInsured: sumInsuredValue
          }).unwrap();
          const {
            data: { item: items }
          } = response;
          dispatch(
            setPremium({ key: code, items, sumInsured: selectSumInsured })
          );
          dispatch(
            setLoading({
              key: index,
              value: false,
              sumInsured: selectSumInsured
            })
          );
        } catch (err) {
          dispatch(
            setLoading({
              key: index,
              value: false,
              sumInsured: selectSumInsured
            })
          );
          if (index === 0) {
            dispatch(
              setModalProps({
                type: 'error',
                code: err.data?.errors?.[0]?.code || '077'
              })
            );
            dispatch(setShowModal(true));
            dispatch(resetLoading());
            return;
          }
          continue;
        }
      }
      if (index === 0) {
        moveNext();
        dispatch(setCoverType(code));
      }
    }
  };

  const checkElligibleCoverCodes = () => {
    if (vehicleType === 'car') {
      const carData = coverCodeArr.filter((code) => code !== '02');
      return oldCar(vix.makeYear) ? carData.reverse() : carData;
    }
    if (vehicleType === 'motorcycle') {
      return coverCodeArr.filter((code) => code !== '03');
    }
  };

  const back = (e) => {
    pushClick(e, {
      'Vehicle type': vehicleType
    });
    movePrev();
  };

  const closeModal = () => dispatch({ type: 'resetModal' });

  return {
    vix,
    confirmVariation,
    next,
    back,
    isLoading:
      isLoading[loadingCode]?.sumInsured ||
      isLoading[loadingCode]?.reducedSumInsured,
    error,
    modalProps: modal,
    closeModal,
    pushClick
  };
};

export default UserVerificationLogic;
