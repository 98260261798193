import React from 'react';
import { Form } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { FormProvider } from 'react-hook-form';

import './CustomerDetails.scss';
import CustomerDetailsLogic from './Logic/CustomerDetails.logic';
import { Info } from 'assets/images';
import { InputText, PaymentBox, StatusModal } from 'components';
import { MainContainer } from 'components/UIComponent';
import { maritalStatusLabels } from 'constants/label';

function CustomerDetails() {
  const {
    confirmVariation,
    formError,
    isLoading,
    modalProps,
    onError,
    onSubmit,
    referralCode,
    showSumInsured,
    t,
    useFormMethod,
    vehicleForm,
    vix
  } = CustomerDetailsLogic();

  return (
    <MainContainer isLoading={isLoading} modalProps={modalProps}>
      <div className="customer-details">
        <div className="options">
          <div className="mb-4">
            <div>
              {`${confirmVariation?.desc} ${vix?.makeYear} - ${vix?.vehRegNo}`}
            </div>
            <div>{`Sum Insured: ${
              !showSumInsured ? confirmVariation?.sumInsured : 0
            } (NCD ${vix?.ncdPerc}%)`}</div>
          </div>
          <div className="fields">
            <FormProvider {...useFormMethod}>
              <Form
                onSubmit={useFormMethod.handleSubmit(onSubmit, onError)}
                id="details-form"
              >
                <div className="mb-3">
                  <InputText
                    fieldControlId="validationCustom01"
                    detectEmpty={() => null}
                    floatingControlId="floatingInput"
                    floatingLabel={t('full_name') + ' *'}
                    fieldType="text"
                    fieldPlaceholder="Owner's Full Name *"
                    defaultValue={vehicleForm?.fullName}
                    formError={formError}
                    register={{
                      control: 'fullName',
                      rules: {
                        required: `${t('name_required')}`,
                        uppercase: true
                      }
                    }}
                  ></InputText>
                </div>
                <div className="mb-3 d-flex flex-wrap">
                  <div className="col-12 col-md-6 spacing-right noBotMargin">
                    <InputText
                      fieldControlId="validationCustom01"
                      detectEmpty={() => null}
                      floatingControlId="floatingInput"
                      floatingLabel={t('email') + ' *'}
                      fieldType="email"
                      fieldPlaceholder={t('email') + ' *'}
                      defaultValue={vehicleForm?.email}
                      formError={formError}
                      register={{
                        control: 'email',
                        rules: {
                          required: `${t('email_required')}`,
                          pattern: {
                            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                            message: `${t('email_message')}`
                          }
                        }
                      }}
                    ></InputText>
                  </div>
                  <div className="info mb-2">
                    <span className="image">
                      <img src={Info} alt="info" />
                    </span>
                    <div className="description">{t('mandatory_desc')}</div>
                  </div>
                  <div className="col-12 col-md-6 spacing-left mobile">
                    <InputText
                      fieldControlId="validationCustom02"
                      detectEmpty={() => null}
                      floatingControlId="floatingInput"
                      floatingLabel={t('mobile') + ' *'}
                      fieldType="number"
                      fieldValue={`0${vehicleForm?.mobileNumber}`}
                      fieldPlaceholder="Mobile Number *"
                      validate={true}
                      read={true}
                      formError={formError}
                      register={{
                        control: 'mobile',
                        rules: {
                          pattern: {
                            value: /^[0-9]+$/,
                            message: 'Please enter a number'
                          }
                        }
                      }}
                    ></InputText>
                  </div>
                </div>
                <div className="small">{t('marital_status')} *</div>
                <div className="wrapper-marital">
                  <Row>
                    <div className="d-flex">
                      <div className={'marital-status'}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDisabled"
                          id="flexRadioDefault1"
                          disabled
                          checked={true}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          <p>
                            {t(
                              maritalStatusLabels?.[vehicleForm.maritalStatus]
                            )}
                          </p>
                        </label>
                      </div>
                    </div>
                  </Row>
                </div>
                <div className="mb-2">{t('current_address')}</div>
                <div className="d-flex flex-wrap">
                  <div className="col-12">
                    <InputText
                      fieldControlId="validationCustom01"
                      detectEmpty={() => null}
                      floatingControlId="floatingInput"
                      floatingLabel={t('address') + ' *'}
                      fieldType="text"
                      fieldPlaceholder="Address *"
                      defaultValue={vehicleForm.address}
                      formError={formError}
                      register={{
                        control: 'address',
                        rules: {
                          required: `${t('address_required')}`,
                          uppercase: true,
                          maxLength: {
                            value: 200,
                            message: 'Address max 200 characters'
                          }
                        }
                      }}
                    ></InputText>
                  </div>
                  <div className="col-12 col-md-6 spacing-right postcode">
                    <InputText
                      fieldControlId="validationCustom02"
                      detectEmpty={() => null}
                      floatingControlId="floatingInput"
                      floatingLabel={t('postcode') + ' *'}
                      fieldType="number"
                      fieldPlaceholder="Postcode *"
                      validate={true}
                      read={true}
                      fieldValue={vehicleForm.postcode}
                      formError={formError}
                      register={{
                        control: 'postcode',
                        rules: {
                          maxLength: {
                            value: 5,
                            message: 'Postcode max 5 characters'
                          },
                          minLength: {
                            value: 5,
                            message: 'Postcode min 5 characters'
                          },
                          pattern: {
                            value: /^[0-9]+$/,
                            message: 'Please enter a number'
                          }
                        }
                      }}
                    ></InputText>
                  </div>
                  <div className="col-12 col-md-6 spacing-left state">
                    <InputText
                      fieldControlId="validationCustom01"
                      detectEmpty={() => null}
                      floatingControlId="floatingInput"
                      floatingLabel={t('state') + ' *'}
                      fieldType="text"
                      fieldPlaceholder="State *"
                      validate={true}
                      read={true}
                      fieldValue={vehicleForm?.state?.toUpperCase()}
                      formError={formError}
                      register={{
                        control: 'state',
                        rules: {}
                      }}
                    ></InputText>
                  </div>
                  <div className="col-12 col-md-6 spacing-right city">
                    <InputText
                      fieldControlId="validationCustom01"
                      detectEmpty={() => null}
                      floatingControlId="floatingInput"
                      floatingLabel={t('City') + ' *'}
                      fieldType="text"
                      fieldPlaceholder="City *"
                      validate={true}
                      read={true}
                      fieldValue={vehicleForm?.city?.toUpperCase()}
                      formError={formError}
                      register={{
                        control: 'city',
                        rules: {}
                      }}
                    ></InputText>
                  </div>
                </div>
                <div className="col-12 col-md-6 spacing-right city">
                  <InputText
                    fieldControlId="validationCustom01"
                    detectEmpty={() => null}
                    floatingControlId="floatingInput"
                    floatingLabel={t('Referral Code (Optional)') + ' *'}
                    fieldType="text"
                    fieldPlaceholder="Referral Code *"
                    formError={formError}
                    defaultValue={referralCode}
                    register={{
                      control: 'referralCode',
                      rules: {
                        maxLength: {
                          value: 12,
                          message: 'Promo code max 12 characters'
                        }
                      }
                    }}
                  ></InputText>
                </div>
              </Form>
            </FormProvider>
          </div>
        </div>
        <PaymentBox
          handleClick={() => null}
          handlePrevious={() => null}
          type={'submit'}
          form={'details-form'}
          isAmountReady={true}
        />
      </div>
    </MainContainer>
  );
}

export default CustomerDetails;
