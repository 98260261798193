/** @jsxImportSource theme-ui */
import React from 'react';

import './PaymentBox.scss';
import PaymentBoxLogic from './Logic/PaymentBox.logic';
import { CloseBtn, PriceBox } from 'assets/images';
import { Loading, LogoInsurer } from 'components';
import { ButtonUi } from 'components/UIComponent';
import { links } from 'constants/label';
import { formatNumber } from 'utils/helper';

function PaymentBox({
  btnLabel,
  form,
  gtmData,
  handleClick,
  isLoading,
  pdfUrl,
  type
}) {
  const {
    benefitCodeToTitle,
    closeModal,
    coverType,
    getAddOns,
    getCoveragePeriod,
    getPlan,
    getPrice,
    getServiceTaxAmt,
    getTotalPrice,
    isMobileView,
    selectedPlan,
    setCloseModal,
    steps,
    t
  } = PaymentBoxLogic();

  return (
    <div className={`payment-details ${closeModal ? 'full-height' : ''}`}>
      <div className={`close ${closeModal ? 'appear' : ''}`}>
        <img
          src={CloseBtn}
          alt="close"
          onClick={() => setCloseModal(!closeModal)}
          style={{ cursor: 'pointer' }}
        />
      </div>
      {!isMobileView || closeModal ? (
        <div id="quotation-container" style={{ padding: '12px' }}>
          <div>
            <div className="header title">{t('premium_detail')}</div>
            <span className="subBlueTitle">
              <LogoInsurer
                companyCode={selectedPlan}
                showName={true}
                showLogo={false}
              />
            </span>
          </div>
          <div>
            <div className="section">
              <div className="detail">
                <div className="label">{t('p_type')}: </div>
                <div className="bold">{coverType}</div>
              </div>
              <div className="detail">
                <div className="label">{t('coverage_period')} </div>
                <div className="bold coverage-period">{getCoveragePeriod}</div>
              </div>
              <div className="detail">
                <div className="label">{t('sum_insured')}: </div>
                <div className="bold">
                  RM {formatNumber(getPlan?.sumInsured)}
                </div>
              </div>
            </div>
            <div className="section">
              <div className="detail">
                <div>{t('basic')}</div>
                <div className="value">RM {getPlan?.tariffPremium}</div>
              </div>
              <div className="detail">
                <div
                  className={'ncd-detail'}
                >{`NCD(${getPlan?.ncdPerc}%)`}</div>
                <div className="ncd-detail bold value">
                  - RM {formatNumber(getPlan?.ncdAmt)}
                </div>
              </div>
            </div>
            <div className="section">
              <div className="bold">{t('add_on')}:</div>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '4fr 1fr',
                  margin: 0
                }}
                className="detail"
              >
                {getAddOns?.map((addOn) => {
                  return (
                    <React.Fragment key={addOn}>
                      <div>{t(benefitCodeToTitle[addOn])}</div>
                      <div className="value">
                        RM {formatNumber(getPrice(addOn))}
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
            <div className="section">
              <div className="detail">
                <div>{t('gross')}</div>
                <div className={'value'}>RM {getPlan?.grossPrem}</div>
              </div>
              <div className="detail">
                <div>{t('rebate')}</div>
                <div className={'value'}>RM 0.00</div>
              </div>
              <div className="detail">
                <div className={''}>{'SST(8%)'}</div>
                <div className={'value'}>RM {getServiceTaxAmt}</div>
              </div>
              <div className="detail">
                <div className={''}>{'Stamp Duty'}</div>
                <div className={'value'}>
                  RM {formatNumber(getPlan?.stampDuty)}
                </div>
              </div>
            </div>
            <div className="section">
              <div className="detail">
                <div className={''}>{t('total')}</div>
                <div
                  className="detail"
                  sx={{
                    fontFamily: 'bold'
                  }}
                >
                  RM {getTotalPrice}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="footer">
        {steps === 3 && (
          <div className={'declaration small'}>
            {t('pay_info')}
            <span className="subBlueTitle">
              <a href={links.TNC} target="_blank" rel="noreferrer">
                {` ${t('terms')}`}
              </a>
            </span>
            , {`${t('pay_info_2')}`}
            <span className="subBlueTitle">
              <a href={pdfUrl} target="_blank" rel="noreferrer">
                {` ${t('product_disclosure')} `}
              </a>
            </span>
            {selectedPlan === '18' || selectedPlan === '97' ? (
              <span>
                ,
                <a
                  className="subBlueTitle"
                  href={links.MOREINFOZURICH}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('product_disclosure2')}
                </a>
              </span>
            ) : (
              ''
            )}
            {t('pay_info_3')}
          </div>
        )}
        <div className="wrapper">
          <div
            className="price-background"
            onClick={() => setCloseModal(!closeModal)}
          >
            <img className="pop-details" src={PriceBox} alt="pop-details" />
            <div
              className="amount-wrapper"
              sx={{
                fontFamily: 'bold',
                fontSize: isMobileView ? '20px' : '26px',
                color: '#3C4043'
              }}
            >
              RM {getTotalPrice}
              {isMobileView ? (
                <div
                  sx={{
                    fontFamily: 'light',
                    color: '#5698FC',
                    fontSize: '12px'
                  }}
                >
                  {t('Price breakdown')}
                </div>
              ) : null}
            </div>
          </div>
          <ButtonUi
            type={type}
            form={form}
            handleClick={handleClick}
            isDisabled={isLoading}
            label={btnLabel || 'next'}
            gtmData={gtmData}
          >
            {isLoading ? <Loading color={'white'} /> : null}
          </ButtonUi>
        </div>
      </div>
      <div
        style={{
          marginTop: '12px',
          fontSize: '12px',
          padding: isMobileView ? '0 12px' : 0
        }}
      >
        {steps === 3
          ? `* ${t('commission_1')}${getPlan?.commiAmt} ${t('commission_2')}`
          : null}
      </div>
    </div>
  );
}

export default PaymentBox;
